<template>
  <section class="hero has-hero-image is-between-small-and-medium">
    <div id="hero_image" :style="'background-image: url(' + heroUri + ')'" />
    <router-link
      v-if="logoUri"
      id="logo"
      to="/"
      aria-label="Home"
    >
      <img class="logo" :src="logoUri" alt="Directory Hero">
    </router-link>
    <div class="hero-body">
      <div class="has-text-centered columns is-multiline is-vcentered">
        <div class="column is-12">
          <h1 v-if="heroText" class="title is-size-2 has-text-white" v-html="heroText" />
        </div>
        <div class="column is-12">
          <nav class="buttons has-text-centered is-centered">
            <router-link
              v-for="directory in directories"
              :key="directory.id"
              :to="`/${directory.slug}/home`"
              class="button is-white has-text-weight-bold is-medium"
            >
              {{ directory.name.toUpperCase() }}
            </router-link>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      directories: (state) => state.directories,
      logoUri: (state) => state.logo_uri,
      heroUri: (state) => state.hero_uri,
      heroText: (state) => state.hero_text,
    }),
  },
};
</script>
