<template>
  <section class="page">
    <HomeHero />
    <div id="stat-panel" class="level has-text-centered">
      <div class="level-item">
        <div>
          <p class="heading">
            Records
          </p>
          <p class="title">
            {{ recordCount.toLocaleString() }}
          </p>
        </div>
      </div>
      <div class="level-item">
        <div>
          <p class="heading">
            Directories
          </p>
          <p class="title">
            {{ directoryCount.toLocaleString() }}
          </p>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="columns is-multiline is-vcentered">
        <router-link
          v-for="(directory, index) in directories"
          :key="directory.slug"
          :to="`/${directory.slug}/home`"
          :class="`column is-${'5775'.charAt(index % 4)} has-text-centered`"
        >
          <div :style="'background-image:url(' + directory.hero_uri + ')'" class="directory">
            <h3 class="title has-text-white is-unselectable">
              {{ directory.name }}
            </h3>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
import HomeHero from '@/components/Global/HomeHero.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState({
      directories: (state) => state.directories,
      recordCount: (state) => state.total_records,
    }),
    ...mapGetters({
      directoryCount: 'directory/count',
    }),
  },
  mounted() {
    if (this.directories.length < 2) {
      this.$router.push({
        name: 'directory-home',
        params: { directory: this.directories[0].slug },
      });
    }

    window.scrollTo(0, 0);

    this.$store.dispatch('getRecordCount');
  },
  components: {
    HomeHero,
  },
};
</script>
